import React from "react"

class AddressSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { country: "" }
  }
  selectCountry(val) {
    const value = val
    this.setState({ country: value })
    this.props.selectCountry(value)
  }
  render() {
    return (
      <div>
        <div className="row">
          <input
            aria-label="Indirizzo 1"
            type="text"
            name="address_line1"
            onChange={this.props.handleChange}
            placeholder="Indirizzo"
            required={this.props.shipCollapse ? true : false}
          />
        </div>
        <div className="row">
          <input
            aria-label="Indirizzo 2"
            type="text"
            name="address_line2"
            onChange={this.props.handleChange}
            placeholder="Indirizzo (appartamento, piano, c/o...)"
          />
        </div>
        <div className="row">
          <input
            aria-label="CAP"
            type="text"
            name="postal_code"
            onChange={this.props.handleChange}
            placeholder="CAP"
            required={this.props.shipCollapse ? true : false}
          />
        </div>
        <div className="row">
          <input
            aria-label="Provincia"
            type="text"
            name="state"
            onChange={this.props.handleChange}
            placeholder="Provincia"
            required={this.props.shipCollapse ? true : false}
          />
        </div>
        <div className="row">
          <input
            aria-label="Città"
            type="text"
            name="city"
            onChange={this.props.handleChange}
            placeholder="Città"
            required={this.props.shipCollapse ? true : false}
          />
        </div>
        <div className="row"></div>
        <div className="row">
          <input
            aria-label="Telefono"
            type="tel"
            name="number"
            onChange={this.props.handleChange}
            placeholder="Telefono (facoltativo)"
          />
        </div>
        {/* <CountryDropdown
          name="country"
          value={this.state.country}
          onChange={val => this.selectCountry(val)}
          priorityOptions={["IT"]}
          defaultOptionLabel={"Seleziona il Paese"}
        /> */}
      </div>
    )
  }
}

export default AddressSection
