import React from "react"
import styled from "styled-components"
const Select = styled.select`
  width: 100%;
  padding: 0.6em 1.4em 0.5em 0.8em;
  margin-bottom: 15px;
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
`
class HandDelivery extends React.Component {
  render() {
    return (
      <div className="row">
        <Select
          name="handDelivery"
          onChange={this.props.handleChange}
          defaultValue=""
          required={this.props.handCollapse ? true : false}
        >
          <option value="" disabled>
            -- seleziona un punto di ritiro --
          </option>
          {/* <option value="Lucca">Lucca Comics & Games</option> */}
          <option value="Magenta">
            Magenta, presso la libreria Il Segnalibro
          </option>
          <option value="Rho">
            Rho, presso la fumetteria L'Idea Che Ti Manca
          </option>
        </Select>
      </div>
    )
  }
}

export default HandDelivery
