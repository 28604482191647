import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CoverMockup from "../components/Images/CoverMockup"
import OrderForm from "../components/Buy/OrderForm"

const Section = styled.section`
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  padding: 110px 20px 110px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  @media only screen and (min-width: 768px) {
    max-width: 500px;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-top: 50px;
  margin: 0 auto;
  max-width: 1200px;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`
const schema = [
  {
    "@context": "https://schema.org",
    "@type": "WebPage",
    // breadcrumb: "Books > Marketing > JSON",
    mainEntity: {
      "@type": "Book",
      // author: "http://www.example.com/author.html",
      bookFormat: "http://schema.org/Book",
      datePublished: "2015-10-31",
      image: "coverImage.jpg",
      inLanguage: "Italian",
      isbn: "00000000",
      name: "Gattista",
      numberOfPages: "76",
      offers: {
        "@type": "Offer",
        availability: "http://schema.org/InStock",
        price: "10",
        priceCurrency: "EUR",
      },
      publisher: {
        "@type": "Organization",
        name: "Il Segnalibro",
        url: "http://www.ilsegnalibromagenta.it/",
      },
    },
  },
]

const BuyPage = () => (
  <Layout>
    <SEO
      title="Acquista"
      schema={schema}
      description="Acquista la Gattivista e aiuta i nostri gattili!"
    />
    <div style={{ backgroundColor: `#ffeb98` }}>
      <Container>
        <div style={{ flex: `0 1 50%` }}>
          <CoverMockup width="500px" />
        </div>
        <Section style={{ flex: `0 1 50%` }}>
          <OrderForm />
        </Section>
      </Container>
    </div>
  </Layout>
)

export default BuyPage
