import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import styled from "styled-components"
import { Link } from "gatsby"
import axios from "axios"
import AddressSection from "./AddressSection"
import NameSection from "./NameSection"
import HandDelivery from "./HandDelivery"
import Consenso from "../Consenso"

const Form = styled.form`
  position: relative;
  width: 100%;
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  div.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
    flex-wrap: nowrap;
  }
  label:not(.form-check-label) {
    width: 30%;
    margin-bottom: 0;
    min-width: 70px;
    padding: 11px 0;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"] {
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #000;
    background-color: transparent;
    -webkit-animation: 1ms void-animation-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }
  #ship + div input {
    border-bottom: solid 1px rgba(211, 211, 211, 0.5);
  }
`
const ShipChoice = styled.fieldset`
  margin: 0 15px 5px;
  padding: 0;
  border-style: none;
  background-color: ${props => props.current ? "#fff" : "#fff5c9"};
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
  }
`
const ShipLabel = styled.div`
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-align: center;
  justify-content: center;
  margin-left: 15px;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0;
  p {
    margin: 0;
  }
`

const Button = styled.button`
  background-color: #ec626b;
  border-radius: 4px;
  border-style: none;
  box-shadow: 0 6px 9px rgba(93, 92, 50, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: 600;
  height: 40px;
  margin: 40px 15px 0;
  outline: none;
  overflow: hidden;
  position: relative;
  width: calc(100% - 30px);
  ::after {
    display: none;
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);

    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;

    /* Center the ripple */
    top: 50%;
    left: 50%;

    animation: ripple 1s infinite;
    opacity: 0;
  }
  /* :focus:not(:active)::after {
     display: block;
  }*/
`

const Container = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  display: -ms-flexbox;
  flex: auto;
  -ms-flex: auto;
  -ms-flex-pack: center;
  flex-direction: column;
  -ms-flex-direction: column;
  justify-content: center;
  margin-left: -20px;
  margin-right: -20px;
  min-width: 100%;
  padding: 0 10px;
  position: relative;
`
const ErrorBox = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin: 0px 15px 20px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
`

const Domande = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin: 0px 15px 20px;
`

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK)
  }
  return stripePromise
}

class OrderForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: undefined,
      name: undefined,
      email: undefined,
      address_line1: undefined,
      address_line2: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
      postal_code: undefined,
      phone: undefined,
      success: false,
      qty: 1,
      price: 0,
      handDelivery: undefined,
      handCollapse: false,
      shipCollapse: false,
      sku: undefined,
      initialAmount: ``,
      text: `Scegli una modalità di consegna`,
    }
  }
  amountUpdate = (price) => {
    this.setState({
      initialAmount: `${price * this.state.qty} €`,
    })
  }
  handleChange = ev => {
    const name = ev.target.name
    let value = ev.target.value
    if (name === "qty" && value && value < 1) {
      value = 1
    }
    this.setState({ [name]: value }, () => this.amountUpdate(this.state.price))
  }

  toggle = ev => {
    this.setState({ error: undefined, text: "Vai al pagamento di " })
    const id = ev.currentTarget.id
    const deliveryByHand = id === "hand" ? true : false
    const price = deliveryByHand ? 10 : 12
    this.setState(
      {
        handCollapse: deliveryByHand,
        shipCollapse: !deliveryByHand,
        price,
        sku: deliveryByHand ? process.env.GATSBY_BY_HAND : process.env.GATSBY_SHIPPED,
      },
      this.amountUpdate(price)
    )
  }
  selectCountry = val => {
    this.setState({ country: val })
  }
  submit = async ev => {
    ev.preventDefault()
    if (
      (!this.state.handCollapse && !this.state.shipCollapse) ||
      (!this.state.handDelivery && !this.state.city)
    ) {
      this.setState({ error: "Scegli una modalità di consegna" })
      return
    }
    this.setState({
      text: `Attendi...`,
      price: 0,
      initialAmount: ``,
    })
    const order = JSON.stringify({
      address: {
        city: this.state.city || this.state.handDelivery,
        country: this.state.country || "",
        address_line1: this.state.address_line1 || "",
        address_line2: this.state.address_line2 || "",
        postal_code: this.state.postal_code || "",
        state: this.state.state || "",
      },
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      charge: {
        qty: this.state.qty,
      },
      confirmed: false,
    })
    await axios
      .post(`${process.env.GATSBY_URL}/.netlify/functions/orderCreate`, order)
      .then(res => {
        console.log(res)
      })
      .catch(function(error) {
        console.log(error)
      })
    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [
        { price: this.state.sku, quantity: parseInt(this.state.qty) },
      ],
      // successUrl: `http://localhost:8000/success`,
      // cancelUrl: `http://localhost:8000/`,
      successUrl: `${process.env.GATSBY_URL}/success`,
      cancelUrl: `${process.env.GATSBY_URL}/`,
      customerEmail: this.state.email,
    })
    if (error) {
      console.warn("Error:", error)
    }
  }

  render() {
    return (
      <Container>
        <h4>Acquista la Gattivista!</h4>
        <Form onSubmit={this.submit} id="form-css">
          <NameSection qty={this.state.qty} handleChange={this.handleChange} />
          <div style={{ padding: `0 15px 20px` }}>
            Scegli una modalità di consegna
          </div>
          <ShipChoice
            id="hand"
            current={this.state.handCollapse}
            onClick={this.toggle}
          >
            <ShipLabel>
              <p>
                <strong>Ritiro a mano</strong>
              </p>
              <p> (10,00 € cad.)</p>
            </ShipLabel>
            {this.state.handCollapse && (
              <HandDelivery
                handCollapse={this.state.handCollapse}
                handleChange={this.handleChange}
              />
            )}
          </ShipChoice>
          <ShipChoice
            id="ship"
            current={this.state.shipCollapse}
            onClick={this.toggle}
          >
            <ShipLabel>
              <p>
                <strong>Spedizione</strong>
              </p>
              <p>(12,00 € cad.)</p>
            </ShipLabel>
            {this.state.shipCollapse && (
              <AddressSection
                handleChange={this.handleChange}
                selectCountry={this.selectCountry}
              />
            )}
          </ShipChoice>
          <Consenso />
          <ErrorBox style={{ display: this.state.error ? `block` : `none` }}>
            {this.state.error}
          </ErrorBox>
          <Button>
            {this.state.text +
              (this.state.price ? this.state.initialAmount : "")}
          </Button>
          <Domande>
            Hai qualche domanda? <Link to="/contatti">Scrivici</Link>
          </Domande>
        </Form>
      </Container>
    )
  }
}

export default OrderForm