import React from "react"
import styled from "styled-components"

const Fieldset = styled.fieldset`
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #fff5c9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`

class NameSection extends React.Component {
  render() {
    return (
      <div>
        <Fieldset>
          <div className="row">
            <label htmlFor="name">Nome</label>
            <input
              aria-label="Nome"
              type="text"
              name="name"
              onChange={this.props.handleChange}
              placeholder="Maria Rossa"
              required
            />
          </div>
          <div className="row">
            <label htmlFor="email">Email</label>
            <input
              aria-label="Email"
              type="email"
              name="email"
              onChange={this.props.handleChange}
              placeholder="maria.rossa@example.com"
              required
            />
          </div>
        </Fieldset>
        <Fieldset>
          <div className="row">
            <label htmlFor="qty">Quantità</label>
            <input
              aria-label="Quantità"
              type="number"
              id="qty"
              name="qty"
              value={this.props.qty}
              onChange={this.props.handleChange}
            />
          </div>
        </Fieldset>
      </div>
    )
  }
}

export default NameSection
