import React from "react"
import styled from "styled-components"
const Fieldset = styled.fieldset`
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #fff5c9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`

const Consenso = () => (
  <Fieldset className="form-check">
    <input
      type="checkbox"
      className="form-check-input"
      id="consenso"
      required
      style={{ marginLeft: `0.5rem` }}
    />
    <label
      className="form-check-label"
      htmlFor="consenso"
      style={{ marginLeft: `1.25rem`, marginRight: `.5rem` }}
    >
      <small>
        Acconsento al trattamento dei dati inseriti nel form per il solo fine di
        gestione dell'ordine
      </small>
    </label>
  </Fieldset>
)

export default Consenso
